<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-3">My job offers</h2>
                            <p v-if="getJobError" class="custom-error mb-3 ml-3"> {{ getJobError }} </p>
                            <div class="profile__content mb-3">
                                <b-row class="mt-1">
                                    <b-col cols="5">
                                        <p v-if="getFilteredUserJobs.length == 0 && getJobsPending && !loader" class="no-results">No job offers</p>
                                    </b-col>
                                    <b-col cols="7">
                                        <router-link to="/add-job-offer" class="details-button details-button--red float-right mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Add job offer</router-link>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-if="getFilteredUserJobs.length == 0 && loader" class="profile__content mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div id="top" v-else class="profile__content">
                                <paginate name="userJobs" :list="getFilteredUserJobs" tag="div" :per="10" class="paginate-list">
                                    <b-row>
                                        <b-col v-for="job in paginated('userJobs')" :key="job.id" cols="12" lg="9">
                                            <div class="profile__element mt-2">
                                                <p class="profile__label">Job offer status</p>
                                                <p v-if="job.status == 1" class="profile__status profile__status--positive">verified</p>
                                                <p v-if="job.status == 2" class="profile__status profile__status--pending">pending</p>
                                                <p v-if="job.status == 3" class="profile__status profile__status--negative">denied</p>
                                                <p v-if="job.status == 3 && job.reason" class="profile__label mt-2">Reason:{{ job.reason }}</p>
                                            </div>
                                            <div class="jobs__element">
                                                <div v-if="job.ribbon" class="custom-ribbon custom-ribbon--right">
                                                    <span class="custom-ribbon__text custom-ribbon__text--green">Featured</span>
                                                </div>
                                                <b-row>
                                                    <b-col cols="10" md="8" lg="6" xl="8">
                                                        <p class="jobs__form">{{ job.type.text }}</p>
                                                        <h2 class="jobs__position" v-line-clamp:22="1">{{ job.position }}</h2>
                                                        <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Company"><simple-line-icons icon="briefcase" size="small" color="#504e70" /></span>{{ job.company }}</span>
                                                        <span class="jobs__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ job.city }}, {{ job.country.text }}</span>
                                                    </b-col>
                                                    <b-col cols="12" md="4" lg="6" xl="4" style="display: flex;">
                                                        <router-link class="details-button details-button--bottom" :to="{ name: 'my-job', params: { id: job.id }}"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="profile__element mt-2">
                                                <b-row>
                                                    <b-col cols="12" sm="4">
                                                        <router-link :to="{ name: 'my-job', params: { id: job.id } }" class="details-button details-button--full details-button--green mt-0"><simple-line-icons icon="eye" size="small" color="#fff" />Preview</router-link>
                                                    </b-col>
                                                    <b-col cols="12" sm="4">
                                                        <router-link :to="{ name: 'edit-job', params: { id: job.id } }" class="details-button details-button--full details-button--yellow mt-0"><simple-line-icons icon="note" size="small" color="#fff" />Edit</router-link>
                                                    </b-col>
                                                    <b-col cols="12" sm="4">
                                                        <button @click.prevent="deleteJob(job.id)" :disabled="getDisabledJob" :class="getDisabledJob ? 'disabled-element' : ''" class="details-button details-button--full details-button--dark-red mt-0"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </paginate>
                                <paginate-links @change="scrollToTop('top')" v-if="getFilteredUserJobs.length != 0" for="userJobs" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import MoveTo from 'moveto'
export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    Spinner,
  },
  data: function () {
    return {
      loader: true,
      paginate: ['userJobs']
    }
  },
  methods: {
    deleteJob (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete this job offer?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setDisabledJob')
          this.$store.dispatch('deleteJob', { id: id })
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.$store.dispatch('clearDisabledJob')
            })
        }
      })
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getJobError',
      'getDisabledJob',
      'getUserJobs',
      'getJobsPending'
    ]),
    getFilteredUserJobs () {
      var jobs = Object.values(this.getUserJobs).reverse()
      return jobs
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledJob')
    this.$store.dispatch('getUserJobs')
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
